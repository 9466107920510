import React from "react";
import { graphql } from "gatsby";

import Layout from "components/layout";
import SEO from "components/seo";

export default function PrivacyPolicyPage({
    data
  }) {
    return (
        <Layout>
            <SEO
                pageData={{
                    title: data.markdownRemark.frontmatter.title,
                    description: data.markdownRemark.frontmatter.metadescription
                }}
            />

            <div className="bg-green-50 border-b border-green-100 mb-12">
                <div className="max-w-screen-md mx-auto px-4 text-3xl md:text-5xl font-black text-center py-16">{data.markdownRemark.frontmatter.title}</div>
            </div>

            <div className="page mx-auto max-w-screen-md mb-12 px-4" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html}} />
        </Layout>
    )
}
export const pageQuery = graphql`
query PrivacyPolicyPageQuery {
    markdownRemark(frontmatter: {section: {eq: "privacy-policy"}}) {
        frontmatter {
            title
            metadescription
        }
        html
    }
}
`